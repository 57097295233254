import gql from 'graphql-tag';

export const GET_SUGGESTIONS = gql`
  query suggestion(
    $storeCode: String!
    $vehicleInfo: VehicleInput
    $suggestionInput: SuggestionInput!
  ) {
    productSearchWs {
      suggestion(
        storeCode: $storeCode
        vehicleInfo: $vehicleInfo
        suggestionInput: $suggestionInput
      ) {
        products {
          code
          description
          brand
          images {
            altText
            format
            url
          }
          categories {
            thumbnail {
              url
            }
          }
          name
          price {
            value
          }
          productType
          size
          url
          suggestedQuantity
          unitName
        }
      }
    }
  }
`;
